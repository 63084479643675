.bg-motto {
  background: linear-gradient(
    91.1deg,
    rgba(250, 250, 250, 0.9) 42.41%,
    rgba(255, 255, 255, 0.486) 73.24%
  );
}

// where max-height: = line-height: × <number-of-lines> in em. */
.max-line-2 {
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 1.8em;
  line-height: 1.8em;
}

.center {
  img {
    width: 400px;
    height: 200px;
  }
}

.slide-container {
  img {
    // background-color: red;
    // object-fit: fill;
    width: 100%;
    height: 100%;
    // transition: all 2s ease-in-out;
    // transform: translateX(-100%);
  }
}

.words {
  word-spacing: 5px;
}

// .bg {
//   background: linear-gradient(
//     173.09deg,
//     #ffd452 -6.69%,
//     #ffffff 23.5%,
//     #fff8e1 46.68%,
//     #fff8e1 89.76%,
//     #ffd452 120.4%
//   );
//   background-repeat: no-repeat !important;
//   background-position: center;
//   height: 100vh;
//   width: 100vw;
//   position: fixed;
//   /* z-index usage is up to you.. although there is no need of using it because the default stack context will work. */
//   z-index: -1; // this is optional
// }

// .content {
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   width: 100%;
// }

blur {
  filter: blur(10px);
}

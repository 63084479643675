// .img2 img {
//   object-fit: contain;
// }
.card1 {
  top: 50%;
  left: 50%;
  //   transform: translate(-50%, -50%);
}

.scale img {
  transform: scaleX(1.3);
}

.scale {
  overflow: hidden;
}

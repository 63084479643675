.accordion-button::after {
  background-image: url(../../assets/icons/down-arrow-svgrepo-com.svg);
}
.accordion-button:not(.collapsed) {
  font-weight: 600;
  color: #000;
  &:after {
    background-image: url(../../assets/icons/down-arrow-black.svg);
    rotate: 180deg;
  }
}

.accordion-item {
  border-radius: 0px !important;
}

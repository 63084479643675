.hover-underline-animation {
  a {
    display: inline-block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 60%;
      transform: scaleX(0);
      height: 2px;
      bottom: 12px;
      left: 20%;
      background-color: #08b05f;
      transform-origin: bottom left;
      transition: all 0.25s ease-out;
    }

    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

.hover-underline-animation-off {
  a {
    &:hover:after {
      transform: scale(0);
    }
  }
}

// .hover-underline-animation {
//   a {
//     display: inline-block;
//     position: relative;
//     color: #0087ca;

//     &:after {
//       content: "";
//       position: absolute;
//       width: 100%;
//       transform: scaleX(0);
//       height: 2px;
//       bottom: 0;
//       left: 0;
//       background-color: #0087ca;
//       transform-origin: bottom right;
//       transition: transform 0.25s ease-out;
//     }

//     &:hover:after {
//       transform: scaleX(1);
//       transform-origin: bottom left;
//     }
//   }
// }

.active-line {
  display: inline-block;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 60%;
    transform: scaleX(0);
    height: 2px;
    bottom: 12px;
    left: 20%;
    background-color: #08b05f;
  }
}

// .shimmer {
//   display: inline-block;
//   color: black;
//   font-size: 48px;
//   font-weight: 900;
//   letter-spacing: 8px;

//   background: #000000 -webkit-gradient(linear, 100% 0, 0 0, from(#000000), color-stop(0.5, #bdbdbd), to(#000000));

//   background-position: -4rem top;
//   background-repeat: no-repeat;
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
//   -webkit-animation-name: shimmer;
//   // -webkit-animation-duration: 10s;
//   // -webkit-animation-iteration-count: infinite;
//   -webkit-background-size: 1.5rem 100%;
// }

.shimmer {
  color: grey;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer infinite;
  animation-duration: 3s;
  // animation-delay: 1s;
  font-size: 50px;
  max-width: 200px;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

// @import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 16px;
  // h1,
  // h2,
  // h3,
  // h4 {
  //   font-family: "Cinzel";
  // }
  overflow-x: hidden;
  // height: 100vh;
  // width: 100vw;
  // background-attachment: local;
  // background-repeat: no-repeat;
  // overflow-y: hidden;
  // background: linear-gradient(
  //   173.09deg,
  //   #ffd452 -6.69%,
  //   #ffffff 23.5%,
  //   #fff8e1 46.68%,
  //   #fff8e1 89.76%,
  //   #ffd452 120.4%
  // );
}

// .bg {
//   background: linear-gradient(
//     173.09deg,
//     #ffd452 -6.69%,
//     #ffffff 23.5%,
//     #fff8e1 46.68%,
//     #fff8e1 89.76%,
//     #ffd452 120.4%
//   );
//   background-repeat: no-repeat !important;
//   background-position: center;
//   height: 100%;
//   // width: 100vw;
//   position: fixed;
//   /* z-index usage is up to you.. although there is no need of using it because the default stack context will work. */
//   z-index: -1; // this is optional
// }

// .content {
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   width: 100%;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.left-section {
  display: grid;
  //   background-color: red;
  grid-template-columns: 1fr 1fr;
  // row-gap: 1em;
  column-gap: 1em;
}
.right-section {
  display: grid;
  //   background-color: red;
  grid-template-columns: 1fr 1fr;
  // row-gap: 1em;
  column-gap: 1em;
}

.group-name {
  // transition: all 3s all;
  animation: fadeIn 2s ease;
}

// .fade-in {
//   animation: fadeIn ease 5s;
//   -webkit-animation: fadeIn ease 5s;
//   -moz-animation: fadeIn ease 5s;
//   -o-animation: fadeIn ease 5s;
//   -ms-animation: fadeIn ease 5s;
// }

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.semi-bold {
  font-weight: 600;
  color: red;
}

// @import url("https://fonts.googleapis.com/css2?family=Laila:wght@700&display=swap");
.card {
  // font-family: "Laila", sans-serif;
}

.parallax {
  /* The image used */
  background-image: url("../../assets/img/paralux.jpg");

  /* Set a specific height */
  min-height: 500px;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.p-card {
  transition: all 0.3s ease-out;
}

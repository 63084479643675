.message-section {
  // margin: 2.8em 0em 2em;
  input,
  textarea {
    border-radius: 0.5rem;
    width: 100%;
    padding: 0.8rem 1.2rem;
    border: 1px solid black;
    margin-bottom: 1rem;
    color: black;
    &:focus {
      outline: none;
      // border: 1px solid #deb887;
      // box-shadow: 0 0 5px #764ba266;
      transition: box-shadow 0.02s ease-in-out, border-color 0.02s ease-in-out;
    }
  }
}

@media screen and (max-width: 1295px) {
  .message-section {
    margin-bottom: 0.5em;
    input,
    textarea {
      padding: 0.5rem 1.2rem;
    }
  }
}
@media screen and (max-width: 993px) {
  .message-section {
    // margin-top: -5px;
  }
}
